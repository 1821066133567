import React, { Fragment } from 'react';
import styled from 'styled-components';


const planPrice = {
  annually: {
    price: '160',
  },
  monthly: {
    price: '18',
  },
};

export const CardWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  gap: 0px;
  padding-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
  }
`;

export const ImageWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 1px;
  grid-column: span 2 / span 2;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    grid-column: span 1 / span 1;
  }
`;

export const TextWrapper = styled.div`
  grid-column: span 8 / span 8;
  padding: 0px 10rem;

  @media (max-width: 768px) {
    grid-column: span 1 / span 1;
    padding: 0px 1rem;
  }
`;

export const Lead = styled.p`
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
  color: rgb(29 78 216);
`;

export const TestimonialCard = () => {
  return (
    <Fragment>
      <div className="container bg-gray-900 text-gray-100" id="pricing">
        <div className="mr-auto ml-auto py-5">
          <CardWrapper>
            <TextWrapper>
              <Lead>
                "My small team uses this to help improve our process and development speed. Our
                non-technical founder plays around with windframe of an evening and when he’s done
                and happy we can easily get the code live, fast, without distracting the dev team
                away from developing the platform. These tools are super handy for small teams and
                are invaluable."
              </Lead>
              <Name> - James R. Hardy </Name>
            </TextWrapper>
          </CardWrapper>
        </div>
      </div>
    </Fragment>
  );
};
