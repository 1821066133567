import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframePricingNav';
import { Footer } from '../../../components/Footer/Footer';
import { Comparison } from '../../../components/Windframe/Windframe/Comparison';
import { TestimonialCard } from '../../../components/Windframe/Windframe/TestimonialCard';
import { HeroTopPricing } from '../../../components/Windframe/Windframe/HeroTopPricing';
import { HeroPricing } from '../../../components/Windframe/Windframe/HeroPricing';
import FAQ from '../../../components/Windframe/Windframe/Faq';
import '../../../styles/scss/style.scss'

const PricingPage = () => {
  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor |
          Visual Builder
        </title>
        <meta
          name="title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          name="description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 120+ easily editable blocks.
"
        />
        <meta
          property="og:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="og:description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 120+ easily editable blocks."
        />
        <meta
          property="twitter:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="twitter:description"
          content="Create breathtaking websites using our No code tailwindcss drag and drop builder in record time. Create a website or build an online presence easily for your brand or business using windframe’s 120+ easily editable blocks."
        />
        <link rel="canonical" href="https://windframe.dev/" />
        <meta property="og:url" content="https://windframe.dev/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div className="bg-gray-900 text-gray-100">
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
              {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
              {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} dark />
            </div>
            <HeroTopPricing />
            <HeroPricing />
            <Comparison />
            <TestimonialCard />
            <FAQ dark />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default PricingPage;
