import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export const HeroPricing = () => {
  return (
    <div className="container bg-gray-900 text-gray-100" style={{ marginBottom: '5rem' }}>
      <div className="feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <h4 className="font-bold lg:text-4xl sm:text-3xl text-xl">
              Building webpages takes a lot of time
            </h4>
            <p className="text-lg leading-7 text-gray-300 sm:mt-8 mt-6">
              An average developer takes around 12 hours - 2 days to code a webpage. When you factor
              in a designer too it takes even more time.
              <br /> <br />
              Windframe does all this and gives you the flexibity to edit your designs visually
              using an intuitve interface. While making it possible to export your code in
              <strong> HTML, REACT or VUE,</strong> which you can easily copy and paste to your own
              codebase
            </p>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/pricingimage.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
