export const questions = [
  {
    id: 1,
    question: 'Can I use the source code generated from Windframe elsewhere',
    answer: 'Yes any code exported from the editor can be integrated in any project of your choice or hosted on any hosting service your choice',
  },
  {
    id: 2,
    question: 'What is the format of the source code exported from Windframe',
    answer: 'The code exported from Windframe is a Html file, this makes it easier to integrate with almost any framework of your choice or host anywhere. You also get the code in other formats like React/Next/Gatsby and Vue/Nuxt but this can only be copied from the editor and not exported as files',
  },
  {
    id: 3,
    question: 'Can I use Windframe to with other other templates or my own custom templates/components/website',
    answer: 'Yes! With the import update to Windframe, you can now use Windframe with any tailwind template, UI library or your own websites or components',
  },
  {
    id: 4,
    question: 'Can I use Tailwind UI with Windframe',
    answer: 'Yes you can, Windframe allows you to import any tailwind library of your choice like Tailwind UI and visually work with it. Just to note, Windframe is an independent project and is not in anyway associated with Tailwind Labs. The awesome creators of Tailwind and Tailwind UI.',
  },
  {
    id: 5,
    question: 'Can I use Windframe to build websites for different clients',
    answer: 'Of course, Windframe supports multiple projects which makes it possible to work on different client projects at once',
  },
  {
    id: 6,
    question: 'What version of Tailwind CSS does Windframe use',
    answer: 'Windframe currently ships with the latest version of Tailwind CSS which is v3. It will also be updated as updates are made to the core Tailwind CSS library',
  },
  {
    id: 7,
    question: 'Do you offer a discount for students and non profits',
    answer: 'Yes we do, just reach out to us at contact@devwares.com or using the form in the contact page. Also letting us know you are a student or a representative of a non profit.',
  },
  // {
  //   id: 8,
  //   question: 'What is your refund policy',
  //   answer: 'You can get a refund for your purchase within the first 24 hours of your purchase. If you are not happy with the product for any reason. Please reach out to me at sampson@devwares.com within a day of making the purchase and you will be fully refunded',
  // },
  {
    id: 9,
    question: 'Why a subscription',
    answer: `Windframe is currently being maintained and worked on by two developers. 
    The subscription model allows us to build a sustainable product and business.
    Sustainablity is very important to us as we want to keep updating Windframe and making it
     a more powerful tool to help build awesome tailwind css pages ( There is still a lot we have planned for Windframe :) ).
     We are also constantly adding new templates to give you more options and all this leads to ongoing cost which the susbscription model helps us cover and makes any spending we make predictable`,
  },
  {
    id: 10,
    question: 'Where can I get support',
    answer: 'Whether night or day please do feel free to reach out to us on contact@devwares.com for any inquiries or issues you might have. We are more than happy to help.',
  },

]
