import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const HeroTopPricing = () => {
  return (
    <div className="py-5 bg-gray-900 text-white">
      <div className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="font-bold lg:text-5xl sm:text-3xl text-xl">
                You should build stunning <br />
                and well crafted webpages
              </h2>
              <p className="text-xl mt-4 mb-12">But you already have so much to do already.</p>
              <div className="hero__buttons mb-4">
                <AnchorLink
                  href="#pricing"
                  className="ml-3 inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 hover:text-gray-200 transition-all duration-200 border-2 border-gray-900 rounded-xl bg-gray-800 hover:bg-gray-800 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Get Windframe Premium
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
