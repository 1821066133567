import React from 'react';
import '../../../styles/scss/_faq.scss'
import { questions } from './questions';

const FAQ = ({ dark }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  React.useEffect(() => {
    const results = questions.filter(item => item.question.toLowerCase().includes(searchTerm));
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <div className="contain">
      <h2 className="comparison__lead">Frequently Asked Questions</h2>
      <Searchbar onSearchChange={handleSearchChange} dark={dark} />
      <section className="faq">
        {searchResults.map(item => (
          <Question key={item.id} question={item.question} answer={item.answer} dark={dark} />
        ))}
      </section>
    </div>
  );
};

const Searchbar = props => {
  const [value, setValue] = React.useState('');
  const handleChange = e => {
    setValue(e.target.value.toLowerCase());
    props.onSearchChange(e);
  };
  return (
    <form className=" w-5/6 bg-gray-900 flex items-center mx-auto ">
     
      <input
        className={'searchbar ' + (props.dark ? '!bg-gray-800 !shadow-none' : '')}
        type="text"
        placeholder="Search for a question"
        onChange={handleChange}
        value={value}
      />
    </form>
  );
};

const Question = props => {
  const [isActive, setActive] = React.useState(false);
  const handleClick = id => {
    setActive(!isActive);
  };
  return (
    <div className={'question-wrapper ' + (props.dark ? '!border-b-gray-800' : '')}>
      <div
        onClick={() => handleClick(props.id)}
        className={'question ' + (props.dark ? '!text-gray-100' : '')}
        id={props.id}
      >
        <h5>{props.question}</h5>
        <button onClick={() => handleClick(props.id)}>
          <svg
            className={isActive ? 'active' : ''}
            viewBox="0 0 320 512"
            width="100"
            title="angle-down"
          >
            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
          </svg>
        </button>
      </div>
      <div className={isActive ? 'answer active' : 'answer'}>
        <h6 className={props.dark ? '!text-gray-300' : ''}>{props.answer}</h6>
      </div>
    </div>
  );
};

export default FAQ;
